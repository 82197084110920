import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Service from "../../components/service"
import { FaRegHandshake } from "react-icons/fa"

const Services = ({ data }) => {
  return (
    <Layout>
      <section className="hero is-medium has-bg-img">
        <div className="hero-body">
          <div className="container">
            <div className="has-text-centered">
              <span className="is-size-1 has-text-white has-text-weight-bold">
                Direct Mail Services
              </span>
              <p className="is-size-4 has-text-white mb-1">
                Grow your business with direct mail marketing.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Service
        slug="direct-mail-postcards"
        title="Direct Mail Postcards"
        description="Postcards are a great tool to use to prospect for new customers,
        or market to your current house list. Your postcards will be
        printed on high quality card stock, which will help you stand
        out from other pieces in the mailbox. We have plenty of size and
        quantity options to perfectly match your budget and project
        needs."
        fluid={data.postcardImage.childImageSharp.fluid}
      />
      <Service
        slug="direct-mail-letters"
        title="Direct Mail Letters"
        description="Direct mail letters are a great tool to use if you have more
        content to promote. Larger in size than most postcards, letters
        will allow you to get your message across and not look
        overcrowded, which can lower response rates. Letters will be
        printed on high quality paper stock, and inserted into quality
        envelopes which will help you stand out from other pieces in the
        mailbox. We have plenty of size and quantity options to
        perfectly match your budget and project needs"
        fluid={data.letterImage.childImageSharp.fluid}
      />
      <Service
        slug="every-door-direct-mail"
        title="Every Door Direct Mail"
        description="Every Door Direct Mail or EDDM, is a great option to reach local
        customers in a cost effective fashion. Select mailing routes
        near your business, and let us print, prep, bundle, and deliver
        your postcards."
        fluid={data.eddmImage.childImageSharp.fluid}
      />
      <section className="hero is-primary mt-5">
        <div className="container py-6 px-3 has-text-white">
          <h3 className="is-size-3 has-text-weight-bold">
            Reach your customers with a message they can hold, keep and
            reference.
          </h3>
          <p className="is-size-4">
            Cutting through today's massive amounts of advertising can be
            daunting. Sometimes, you need to reach a prospect seven, eight or
            nine times before they respond. Direct Mail is delivered daily,
            collected regularly and read consistently. With Direct Mail
            Marketing, recipients can keep physical reminders of your business
            and services on file, taped to their refrigerator or slipped into
            their calendar. Arrive in the mail and be on hand when needed.
          </p>
        </div>
      </section>
      <section className="py-6">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h3 className="is-size-4 has-text-weight-bold has-text-centered my-5">
                Personalized Touches
              </h3>
              <p className="is-size-5">
                Everyone likes to see their names' in print. Gone are the days
                of "Dear Sir." Personalizing your direct mail marketing message
                with individually addressed pieces lets customers and prospects
                know they matter.
              </p>
            </div>
            <div className="column">
              <h3 className="is-size-4 has-text-weight-bold has-text-centered my-5">
                Target Your Audience
              </h3>
              <p className="is-size-5">
                Maximize your reach and improve your return with a focused
                direct mail campaign. Smaller targeted direct mail lists allow
                you to choose specific recipients demographics such as the stay
                at home spouse, business professional or high school senior.
              </p>
            </div>
            <div className="column">
              <h3 className="is-size-4 has-text-weight-bold has-text-centered my-5">
                Let People Hold Your Message
              </h3>
              <p className="is-size-5">
                Direct mail marketing gets in your customers' hands. It provides
                a physical interaction. Want to ramp up your impact? Interact
                with your customers through additional call to actions such as
                phone numbers, landing pages, or coupons.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    postcardImage: file(
      absolutePath: { regex: "/direct-mail-postcards-services/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    letterImage: file(absolutePath: { regex: "/direct-mail-letters/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    eddmImage: file(
      absolutePath: { regex: "/seattle-direct-mail-postcards/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Services
