import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default function Service({ slug, title, fluid, description }) {
  return (
    <section className="mb-5">
      <div className="container pt-5 px-3">
        <div className="columns is-vcentered">
          <div className="column is-5">
            <Img fluid={fluid} />
          </div>
          <div className="column is-6 is-offset-1">
            <h3 className="has-text-black is-size-4 has-text-weight-bold mb-3">{title}</h3>
            <p className="mb-3 is-size-4">{description}</p>
            <Link className="button is-danger" to={`/direct-mail/${slug}`}>Learn More {">"}</Link>
          </div>
        </div>
      </div>
    </section>
  )
}
